import FlowDesignVar from "@/views/sysviews/flow/define/ts/flowDesign/flowDesignVar";
import RaphaelDragUtil from "@/views/sysviews/flow/define/ts/flowDesign/raphaelDragUtil";
import ConnectUtil from "@/views/sysviews/flow/define/ts/flowDesign/connectUtil";
export default class FlowUtil {
    //画点（折线时用）
    public static async drawPoint(options:any):Promise<void>{
        if(FlowDesignVar.connStatus!=true || ''==FlowDesignVar.startNodeId)return;//如果没有先点击顶部按钮中的连线按钮或者没有点击开始节点，而是直接点击画布，就不做任何处理
        let selfId=await FlowDesignVar.inst.utils.Api.getNewId();//后端生成id
        // let selfId=FlowDesignVar.inst.utils.UtilPub.randomString(20);//前端生成id，就是有重复的风险，但是几乎不可能重复
        let node = FlowDesignVar.r.image(FlowDesignVar.inst.flowImages.endTaskImg, parseInt(options.x), parseInt(options.y), 10, 10);//画一个点（用img伪装，这样可以拖动）
        node.selfId=selfId;
        node.nodeSelfType=FlowDesignVar.connectPoint;
        FlowUtil.nodeBindEvent(node);//绑定节点事件（主要是拖动）
        FlowDesignVar.canvas.nodeArray.push(node);
        let startNode=FlowUtil.getNodeBySelfId(FlowDesignVar.startNodeId);//根据节点id找到点击的开始节点

        if(startNode){
            let connectNodes=await ConnectUtil.beforeDrawPointConnect({ obj1: startNode, obj2:node});
            if(connectNodes){
                FlowDesignVar.connectNodes.push(FlowDesignVar.r.drawConnect(connectNodes));//画连线
                FlowDesignVar.connectIds.push(connectNodes.obj1.selfId+"####"+connectNodes.obj2.selfId);
            }
            // FlowDesignVar.connectNodes.push(FlowDesignVar.r.drawConnect({ obj1: startNode, obj2:node}));//画连线
        }
        FlowDesignVar.startNodeId=node.selfId;//把开始节点换成新画的节点
    }
    //绘制img和text以及绑定他们的关系和事件
    public static drawImgNode_textNode(options:any):any{
        let r=60;
        if(options.nodeSelfType==FlowDesignVar.connectPoint)r=10;//如果是折线上的点，则把半径整小一点
        let node = FlowDesignVar.r.image(options.img, parseInt(options.x), parseInt(options.y), r, r);//在画布上创建节点image（节点采用图片形式）
        if(options.nodeSelfType!=FlowDesignVar.connectPoint){//如果是折线的点，则没有文本
            let nodeText=FlowDesignVar.r.text(parseInt(options.x)+30, parseInt(options.y)+30,options.text);//在节点的中间创建一个文本节点text（节点采用文本形式）
            if(options.attr){ //在单据上打开流程定义显示流程图的时候，会传入该单据进行到的步骤。这个时候需要把该单据流程进行到的步骤标红,会传入额外的样式
                nodeText.attr(options.attr);
            }else{
                nodeText.attr(FlowDesignVar.fontCss);
            }
            nodeText.selfId=options.childSelfId;
            nodeText.parentNode = node;
            node.childNode=nodeText;
            FlowUtil.nodeBindEvent(nodeText);//绑定节点事件
        }
        node.selfId=options.selfId;
        node.nodeSelfType=options.nodeSelfType;
        node.nodeProperty=options.nodeProperty;
        FlowUtil.nodeBindEvent(node);//绑定节点事件
        FlowDesignVar.canvas.nodeArray.push(node);
    }
    //根据传入的信息在画布上画出节点，并返回两者的连线关系（目前是打开页面的时候，初始化调用）
    public static initNodes(listNodeBeans:any):any{
        FlowDesignVar.connectIds=[];
        //得出节点信息，然后根据这些节点信息画出节点
        for(let i=0;i<listNodeBeans.length;i++){
            let selfId=listNodeBeans[i].selfId;//节点id
            let nodeSelfType=listNodeBeans[i].nodeSelfType;//节点类型（start、end、must_task、user_task、connectPoint）
            let options:any={
                img:FlowUtil.getImgByNodeSelfType(nodeSelfType),
                x:listNodeBeans[i].x,//节点的位置
                y:listNodeBeans[i].y,
                text:listNodeBeans[i].text,//节点显示的文本（孩子节点text显示文本）
                selfId:selfId,//节点id
                childSelfId:listNodeBeans[i].childSelfId,//该节点的孩子节点（text）id
                nodeSelfType:nodeSelfType,
                nodeProperty:listNodeBeans[i].nodeProperty,//节点属性
            }
            //在单据上打开流程定义显示流程图的时候，会传入该单据进行到的步骤。这个时候需要把该单据流程进行到的步骤标红
            if(FlowDesignVar.engine.engineParams.otherParams && FlowDesignVar.engine.engineParams.otherParams.curTask==selfId){
                options.attr={"font-size": 20, stroke: 'red', "stroke-width": 2}
            }
            FlowUtil.drawImgNode_textNode(options);

            //根据该节点创建出连接信息，包括向前和向后节点的连接信息，放到connectIds数组中，放入connectIds数组的时候，要判断connectIds数组中是否已经存在了
            let preNodeSelfIds=listNodeBeans[i].preNodeSelfIds;
            for(let j=0;j<preNodeSelfIds.length;j++){
                let preNodeSelfId=preNodeSelfIds[j];
                if(FlowDesignVar.connectIds.indexOf(preNodeSelfId+"####"+selfId)<0)FlowDesignVar.connectIds.push(preNodeSelfId+"####"+selfId);
            }
            let nextNodeSelfIds=listNodeBeans[i].nextNodeSelfIds;
            for(let j=0;j<nextNodeSelfIds.length;j++){
                let nextNodeSelfId=nextNodeSelfIds[j];
                if(FlowDesignVar.connectIds.indexOf(selfId+"####"+nextNodeSelfId)<0)FlowDesignVar.connectIds.push(selfId+"####"+nextNodeSelfId);
            }
        }
    }
    //根据画布上的节点重绘一下(目前是删除连线之后要重绘)
    public static reDraw():void{
        //清空节点数组，把现在画布上的节点重新放进数组里面去
        FlowDesignVar.canvas.nodeArray=[];
        FlowDesignVar.r.forEach((node:any)=>{//把目前已有的节点备份到FlowDesignVar.canvas.nodeArray里面
            if(node.nodeSelfType)FlowDesignVar.canvas.nodeArray.push(node);//找节点，而不是连线。所有的点都有属性nodeSelfType
        });
        FlowDesignVar.r.clear();//备份完成之后，清空画布，后面好重绘
        let connectIds=[];//找到剩余节点的连线关系，然后放到connectIds里面，方便后面重绘节点之间的连线
        for (let j = FlowDesignVar.connectNodes.length; j--;) {
            let beganId=FlowDesignVar.connectNodes[j].obj1.selfId;
            let endId=FlowDesignVar.connectNodes[j].obj2.selfId;
            if(connectIds.indexOf(beganId+"####"+endId)<0)connectIds.push(beganId+"####"+endId);
        }
        //重绘节点
        let nodes=[...FlowDesignVar.canvas.nodeArray];//先把FlowDesignVar.canvas.nodeArray备份出来
        FlowDesignVar.canvas.nodeArray=[];//清除FlowDesignVar.canvas.nodeArray，因为画节点的时候，会再次把画好的节点存入FlowDesignVar.canvas.nodeArray里面的
        let len=nodes.length;
        for(let i=0;i<len;i++){
            let nodeSelfType=nodes[i].nodeSelfType;//节点类型
            FlowUtil.drawImgNode_textNode({
                img:FlowUtil.getImgByNodeSelfType(nodeSelfType),
                x:nodes[i].attrs.x,//节点的位置
                y:nodes[i].attrs.y,
                text:nodes[i].childNode?nodes[i].childNode.attrs.text:'',//节点显示的文本（孩子节点text显示文本）
                selfId:nodes[i].selfId,//节点id
                childSelfId:nodes[i].childNode?nodes[i].childNode.selfId:'',//该节点的孩子节点（text）id
                nodeSelfType:nodeSelfType,
                nodeProperty:nodes[i].nodeProperty,//节点属性
            });
        }
        ConnectUtil.drawNodeConnectByConnectIds(connectIds);//绘制节点之间的连线信息
    }
    //给节点绑定事件
    public static nodeBindEvent(node:any):void{
        node.drag((dx:any, dy:any, x:any, y:any, e:any) =>{//节点拖动
            RaphaelDragUtil.move(dx,dy,node)
        }, ()=>{//tmd，注意啦，这里必须把RaphaelDragUtil.start(node);写在函数里面，如果直接写成RaphaelDragUtil.start(node);是不会被调用的，搞了好久
            RaphaelDragUtil.start(node);
        }, ()=>{
            RaphaelDragUtil.end(node)
        }); //为节点绑定拖动节点事件
        node.click(async ()=>{//节点单击（处理连线）
            if(FlowDesignVar.connStatus!=true)return;//如果没有先点击顶部按钮中的连线按钮，而是直接点击画布中的节点，就不做任何处理
            if(node.parentNode)node=node.parentNode;//对于连线：点击开始节点，然后结束节点就可以了，但是你有可能点击的是节点上的文字，这样有可能就取不到id，就不能连线，这里找了我好久，所以才判断一下，如果点击的是文字节点就把父节点给找出来
            //如果startNodeId不为空，表示点击连线之后，已经点击过一个开始节点了，startNodeId==node.selfId点击的这个节点和开始点击的节点是同一个节点
            if(''==FlowDesignVar.startNodeId){//为空说明点击连线按钮之后，才开始点击第一个要连线的节点
                FlowDesignVar.startNodeId=node.selfId
            }else if(FlowDesignVar.startNodeId==node.selfId){
                FlowDesignVar.inst.$message({type:'warning',message:'不能自己和自己连线'});
            }else{
                //检查两个节点之间是否有连线了
                let canConnect=true;
                for(let i=0;i<FlowDesignVar.connectNodes.length;i++){
                    let obj1=FlowDesignVar.connectNodes[i].obj1;
                    let obj2=FlowDesignVar.connectNodes[i].obj2;
                    if((obj1.selfId==FlowDesignVar.startNodeId && obj2.selfId==node.selfId) || (obj2.selfId==FlowDesignVar.startNodeId && obj1.selfId==node.selfId)){
                        FlowDesignVar.inst.$message({type:'warning',message:'该两个节点之间已经有连线了'});
                        canConnect=false;
                        break;
                    }
                }
                let startNode=FlowUtil.getNodeBySelfId(FlowDesignVar.startNodeId);//根据节点id找到点击的开始节点
                let connectNodes=await ConnectUtil.beforeDrawPointConnect({ obj1: startNode, obj2:node});
                if(canConnect && startNode && connectNodes){
                    FlowDesignVar.connectNodes.push(FlowDesignVar.r.drawConnect(connectNodes));
                    FlowDesignVar.connectIds.push(connectNodes.obj1.selfId+"####"+connectNodes.obj2.selfId);
                }
                FlowUtil.setConnStatus(false);//连接成功之后，需要清除一些信息，方便下次连接（即便连接不成功，操作错误，也需要重新点击连线按钮进行连线操作）
            }
        }); //绑定单击节点事件
        node.dblclick(function(){//节点双击事件
            if(node.nodeSelfType==FlowDesignVar.connectPoint)return;//如果双击的是点，则不管了
            if(node.parentNode)node=node.parentNode;//由于nodeProperty是设置到image节点上的，所以在打开属性弹出框之前，要为openNodeDialog传入image节点才行，这样才能找到nodeProperty
            if(!FlowDesignVar.engine.engineParams.otherParams)FlowDesignVar.inst.openNodeDialog(node);
        });

        node.node.onmousedown = function (e:any) {//节点右键菜单事件
            if(e){e.stopPropagation();}else{
                // @ts-ignore
                window.event.cancelBubble=true;}
            e = e || window.event;
            if (e.button == 2 && !FlowDesignVar.engine.engineParams.otherParams) {
                FlowUtil.closeContextMenu();//先关闭所有的右键菜单，否则一直点击右键，屏幕上会出现很多右键菜单
                if(node.nodeSelfType==FlowDesignVar.connectPoint){
                    FlowDesignVar.pointMenu.create(e.clientX + document.body.scrollLeft, e.clientY + document.body.scrollTop,node);
                }else{
                    FlowDesignVar.nodeMenu.create(e.clientX + document.body.scrollLeft, e.clientY + document.body.scrollTop,node);
                }
            }
        }
    }
    //给画布绑定上右键事件
    public static bodyBindEvent(id:string):void{
        let divObj:any = document.getElementById(id);
        divObj.onmousedown = (e:any)=> {
            FlowUtil.drawPoint({
                x:e.clientX-55,//这样创建的图片刚好在鼠标点击的中间
                y:e.clientY-120,
            })
            e = e || window.event;
            this.closeContextMenu();
            if (e.button == 2 && !FlowDesignVar.engine.engineParams.otherParams) {
                FlowDesignVar.bodyMenu.create(e.clientX + document.body.scrollLeft, e.clientY + document.body.scrollTop,divObj);
            }
        }
    }
    //关闭右键菜单
    public static closeContextMenu():void{
        if (FlowDesignVar.connectMenu.is_pop)FlowDesignVar.connectMenu.close();
        if (FlowDesignVar.bodyMenu.is_pop)FlowDesignVar.bodyMenu.close();
        if (FlowDesignVar.nodeMenu.is_pop) FlowDesignVar.nodeMenu.close();
        if (FlowDesignVar.pointMenu.is_pop) FlowDesignVar.pointMenu.close();
    }
    //点击连线的时候，把当前connStatus改为true，表示想要连线两个节点,传入false，表示清除连线动作，不管传入true/false，都要把startNodeId置空，至于什么时候给他赋值，交给单击节点的时候来处理
    public static setConnStatus(status:boolean):void{
        FlowDesignVar.connStatus=status;
        FlowDesignVar.startNodeId='';
    }
    //根据selfId从当前画布中寻找节点
    public static getNodeBySelfId(selfId:any):any{
        return FlowDesignVar.canvas.nodeArray.find((node:any) => node.selfId === selfId);
    }
    //根据节点类别得到图片类型
    public static getImgByNodeSelfType(nodeSelfType:string):any{
        let img=FlowDesignVar.inst.flowImages.userTaskImg;//节点的默认图片（任务图片）
        switch (nodeSelfType) {
            case 'start':img=FlowDesignVar.inst.flowImages.startTaskImg;break;
            case 'end':img=FlowDesignVar.inst.flowImages.endTaskImg;break;
            case 'must_task':img=FlowDesignVar.inst.flowImages.mustTaskImg;break;
            case 'connectPoint':img=FlowDesignVar.inst.flowImages.endTaskImg;break;
        }
        return img;
    }
    //删除转折点或者具体的流程步骤节点
    public static deleteNode(node:any):void{
        FlowDesignVar.canvas.nodeArray=FlowDesignVar.canvas.nodeArray.filter((item:any)=>{
            if(item.selfId==node.selfId){
                if(item.childNode)item.childNode.remove();//折线点没有childNode
                item.remove();
                return false;
            }else{
                return true;
            }
        })
    }
    //改变节点连线提交方向。node是连线上的文字节点
    public static changeDirection(node:any):void{
        let nodeProperty=node.nodeProperty;//看连线还有其它属性没有，比如转移条件,备份下来
        let selfIds:Array<string>=node.selfId.split('####');//返回是一个数组，结束id的节点肯定是具体的流程步骤节点
        //beganNode、endNode至少其中一个是具体的流程步骤节点，因为只有这样连线上才有文本节点，如果只是两个点之间是不会有文本的。
        let beganNode=FlowUtil.getNodeBySelfId(selfIds[0]);//先备份下来，因为连线两端的节点要被删掉
        let endNode=FlowUtil.getNodeBySelfId(selfIds[1]);//如果只有一个是具体流程步骤节点，那么一定是endNode,因为箭头是从点指向的具体流程步骤节点
        //删除旧的连接文本、连线两端的节点、FlowDesignVar.connectIds里面对应的连接信息
        FlowDesignVar.canvas.connectTextArray=FlowDesignVar.canvas.connectTextArray.filter((item:any)=>item.selfId.indexOf(node.selfId)==-1);
        FlowDesignVar.connectNodes=FlowDesignVar.connectNodes.filter((item:any)=>(item.obj1.selfId+"####"+item.obj2.selfId)!=node.selfId);
        FlowDesignVar.connectIds=FlowDesignVar.connectIds.filter((item:any)=>item.indexOf(node.selfId)==-1);
        if(beganNode.nodeSelfType==FlowDesignVar.connectPoint){//beganNode是一个转折线上的点
            let newConnect={obj1:endNode,obj2:beganNode};//老的连接在上面被干掉了，于是要重新构造连接，只删除文本好像不行，不知道为什么
            FlowDesignVar.connectNodes.push(FlowDesignVar.r.drawConnect(newConnect));
            FlowDesignVar.connectIds.push(newConnect.obj1.selfId+"####"+newConnect.obj2.selfId);
            //然后把新构建的连接进行递归，根据obj2找到与obj2相连的线段，改变他们的方向(即便是两个点相连，其实他们都有方向的)
            FlowUtil.reConnect(newConnect.obj1,newConnect.obj2,nodeProperty);
        }else{//beganNode是一个具体的流程步骤节点
            let connectNodes={obj1:endNode,obj2:beganNode,nodeProperty:nodeProperty};
            FlowDesignVar.connectNodes.push(FlowDesignVar.r.drawConnect(connectNodes));//画连线
            FlowDesignVar.connectIds.push(connectNodes.obj1.selfId+"####"+connectNodes.obj2.selfId);
        }
        FlowUtil.reDraw();
    }
    //根据beganNode、endNode递归找到整条线上的所有节点，让他们方向变为：beganNode--->endNode--->......
    public static reConnect(beganNode:any,endNode:any,nodeProperty:any):void{
        let connectId=beganNode.selfId+endNode.selfId;//接下来找包含endNode的节点，让找到节点中的endNode变为开始节点，当然要除掉connectId
        for(let i=0;i<FlowDesignVar.connectNodes.length;i++){//只能用for循环，如果用find的话，递归调用返回值有误
            if(FlowDesignVar.connectNodes[i].obj1.selfId+FlowDesignVar.connectNodes[i].obj2.selfId==connectId)continue;
            if(FlowDesignVar.connectNodes[i].obj1.selfId==endNode.selfId){//当前组的obj1就是上一组的endNode，则不用改变方向，本来就是这个方向
                FlowUtil.reConnect(endNode,FlowDesignVar.connectNodes[i].obj2,nodeProperty);continue;
            }else if(FlowDesignVar.connectNodes[i].obj2.selfId==endNode.selfId){//当前组的obj2才是上一组的endNode，则需要改变方向
                let obj1=FlowDesignVar.connectNodes[i].obj1;
                let obj2=FlowDesignVar.connectNodes[i].obj2;
                //哪怕是两个点之间都有一个空的文本，我们要删除这个空文本，然后重建，否则旧的文本还在，保存的时候又用到了connectTextArray，就会出错
                FlowDesignVar.canvas.connectTextArray=FlowDesignVar.canvas.connectTextArray.filter((item:any)=>item.selfId.indexOf(obj1.selfId+"####"+obj2.selfId)==-1);
                FlowDesignVar.connectIds=FlowDesignVar.connectIds.filter((item:any)=>item.indexOf(obj1.selfId+"####"+obj2.selfId)==-1);
                FlowDesignVar.connectIds.push(obj2.selfId+"####"+obj1.selfId);//放入改变了方向的新连接
                if(obj1.nodeSelfType!=FlowDesignVar.connectPoint || obj2.nodeSelfType!=FlowDesignVar.connectPoint){//如果这是末端了（有具体的流程步骤节点）
                    FlowDesignVar.connectNodes.splice(i,1);//删除旧的，下面创建新的。由于已经是末端了，这样操作不会影响for循环（完事之后就退出循环了）
                    FlowDesignVar.connectNodes.push(FlowDesignVar.r.drawConnect({obj1:obj2,obj2:obj1,nodeProperty:nodeProperty}));
                    break;
                }else{//还是两个点连接，需要递归
                    FlowDesignVar.connectNodes[i].obj1=obj2;
                    FlowDesignVar.connectNodes[i].obj2=obj1;
                    let path=FlowDesignVar.connectNodes[i].arrPath.attrs.path;
                    ConnectUtil.createConnectText({obj1:obj2,obj2:obj1,path:path,type:1,nodeProperty:''});
                    FlowUtil.reConnect(FlowDesignVar.connectNodes[i].obj2,FlowDesignVar.connectNodes[i].obj1,nodeProperty);
                }
            }
        }
    }
}