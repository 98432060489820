import FlowDesignVar from "@/views/sysviews/flow/define/ts/flowDesign/flowDesignVar";
import FlowUtil from "@/views/sysviews/flow/define/ts/flowDesign/flowUtil";

export default class ConnectUtil {
    //根据connectIds绘制两个节点之间的连线。connectIds存放：A节点ID####B节点ID
    public static drawNodeConnectByConnectIds(connectIds:Array<any>):void{
        FlowDesignVar.connectNodes=[];//先清空connectNodes，因为画好连线之后，会往里面重新放入节点，不清空会重复增加
        //根据连接信息，找到连接节点，放到connections数组中
        for(let i=0;i<connectIds.length;i++){
            let connectId=connectIds[i].split("####");
            let beganSelfId=connectId[0];
            let endSelfId=connectId[1];
            let beganNode,endNode;
            for(let j=0;j<FlowDesignVar.canvas.nodeArray.length;j++){
                if(beganNode && endNode) break;//如果节点开始和结束都找到了则跳出本次循环
                if(!beganNode && beganSelfId==FlowDesignVar.canvas.nodeArray[j].selfId)beganNode=FlowDesignVar.canvas.nodeArray[j];
                if(!endNode && endSelfId==FlowDesignVar.canvas.nodeArray[j].selfId)endNode=FlowDesignVar.canvas.nodeArray[j];
            }
            if(beganNode && endNode)FlowDesignVar.connectNodes.push({obj1:beganNode,obj2:endNode});
        }
        //重绘节点之间的连线
        for (let j = FlowDesignVar.connectNodes.length; j--;) {
            FlowDesignVar.r.drawConnect(FlowDesignVar.connectNodes[j]);
        }
    }
    //创建两个节点连线上的文字
    public static createConnectText(options:any):any{
        let {type,obj1,obj2}=options;//type:是否创建连线上的文本文字标识（文本对象一定会创建的）
        //下面要用到比如：obj2.childNode.attrs.text。所以把父节点找出来（转折点则不需要管这里）
        if(obj1.type=='text')obj1=obj1.parentNode;
        if(obj2.type=='text')obj2=obj2.parentNode;

        let arr = options.path.join(",").split(",");
        let tempx1 = arr[1];
        let tempy1 = arr[2];
        let tempx2 = arr[4];
        let tempy2 = arr[5];

        let xx = Math.min(tempx1, tempx2);
        let yy = Math.min(tempy1, tempy2);

        let mx = Math.abs(Math.abs(tempx1) - Math.abs(tempx2)) / 2 + Number(xx);
        let my = Math.abs(Math.abs(tempy1) - Math.abs(tempy2)) / 2 + Number(yy);
        let text='';
        if(type==0){//需要创建连线上的文字
            let textInfo=obj2.childNode.attrs.text;//箭头指向节点上面的文字节点内容
            text="提交"+textInfo;
        }
        let connectTextId=obj1.selfId+"####"+obj2.selfId;//两个节点的id
        let connectText= FlowDesignVar.r.text(mx, my,text);//创建连线节点上面的文字节点
        connectText.selfId=connectTextId;//为文字节点添加id
        connectText.attr(FlowDesignVar.fontCss);//为文字节点添加样式
        let nodeProperty=options.nodeProperty;//该连接文本节点的连接属性设置
        //以下循环是为了从connectTextArray中删除将要创建的文字节点，否则你拖动的时候，会创建出多个文字节点来,
        //既然创建出来了，为什么又要删除？上面说了，不删除的话，拖动的时候，会有多个文字节点，删除了之后，在最下方把新创建的又加入到数组中了
        //这样在拖动的时候，同一个文本就只有一个，否则会创建出N个，拖动的时候出现阴影
        FlowDesignVar.canvas.connectTextArray=FlowDesignVar.canvas.connectTextArray.filter((item:any)=>{
            if(item.selfId==connectTextId){
                nodeProperty=item.nodeProperty;
                item.remove();
                return false;
            }
            return true;
        })
        //为文本节点赋值连接属性设置
        //连接属性有几种来源，查找的优先级依次为：1）外界传入了nodeProperty 2）被删除的连接文本节点设置过了nodeProperty 3）数据库中加载了所有的连接属性放到FlowDesignVar.connectProperties中。
        //为什么用这样的优先级呢？当刚打开流程的时候，连接节点上是不会有nodeProperty的，而是把所有的连接属性放到了FlowDesignVar.connectProperties中，这个时候，如果你重新设置了某个转移节点的连接
        //属性，那么那个转移节点上就有nodeProperty了，所以连接节点上的nodeProperty优先级要高于FlowDesignVar.connectProperties；如果创建连接节点的时候，直接指定了nodeProperty，优先级最高了。
        if(nodeProperty){
            connectText.nodeProperty=nodeProperty;
        }else{//给连线节点赋值nodeProperty
            for(let i=0;i<FlowDesignVar.connectProperties.length;i++){
                if(FlowDesignVar.connectProperties[i].began_endId==connectTextId){
                    connectText.nodeProperty=FlowDesignVar.connectProperties[i].nodeProperty;break;
                }
            }
        }

        //删除旧的文字节点之后，添加新创建的文字节点到connectTextArray数组，其实改变的是位置
        FlowDesignVar.canvas.connectTextArray.push(connectText);
        //绑定双击节点事件
        connectText.dblclick(()=>{
            if(!FlowDesignVar.engine.engineParams.otherParams)FlowDesignVar.inst.openConditionNodeDialog(connectText);
        });
        //绑定右键菜单事件
        connectText.node.onmousedown = function (e:any) {
            if(e){e.stopPropagation();}else{
                // @ts-ignore
                window.event.cancelBubble=true;}
            e = e || window.event;
            FlowUtil.closeContextMenu();
            if (e.button == 2 && !FlowDesignVar.engine.engineParams.otherParams) {
                FlowDesignVar.connectMenu.create(e.clientX + document.body.scrollLeft, e.clientY + document.body.scrollTop,connectText);
            }
        }
        return connectText;
    }
    //检查节点是否存在连线（删除节点之前，检查节点是否存在连线，如果存在，则不能删除）
    public static checkNodeExistConnect(node:any):boolean{
        let result:boolean=false;
        let len:number=FlowDesignVar.connectNodes.length;
        for(let i=0;i<len;i++){
            let obj1=FlowDesignVar.connectNodes[i].obj1;
            let obj2=FlowDesignVar.connectNodes[i].obj2;
            if(obj1.selfId==node.selfId || obj2.selfId==node.selfId)return true;
        }
        return result;
    }
    //删除节点之间的连线（不是真正的删除，而是删除我们存放到数组里面的信息，然后根据删除之后的数组重绘流程来实现删除）
    public static deleteConnect(node:any):void{
        let connectSelfId=node.selfId;//node其实是连线上的文字节点，它的id=开始节点id+####+结束节点id
        let len=FlowDesignVar.connectNodes.length;
        let connectionsBak=[...FlowDesignVar.connectNodes];
        for(let i=0;i<len;i++){
            let obj1=FlowDesignVar.connectNodes[i].obj1;
            let obj2=FlowDesignVar.connectNodes[i].obj2;
            let _connectSelfId=obj1.selfId+"####"+obj2.selfId;
            FlowDesignVar.connectIds.splice(FlowDesignVar.connectIds.indexOf(_connectSelfId), 1)
            if(_connectSelfId==connectSelfId){
                connectionsBak = connectionsBak.filter(item => (item.obj1.selfId+"####"+item.obj2.selfId)!=_connectSelfId);
                let connectTextArrayLen=FlowDesignVar.canvas.connectTextArray.length;
                for(let j=0;j<connectTextArrayLen;j++){
                    if(_connectSelfId==FlowDesignVar.canvas.connectTextArray[j].selfId){
                        FlowDesignVar.canvas.connectTextArray.splice(j,1);//删除FlowDesignVar.canvas.connectTextArray中存放的连线上的文字节点
                        break;
                    }
                }
                break;
            }
        }
        FlowDesignVar.connectNodes=connectionsBak;
    }
    //删除折线节点（折线节点右键事件）
    public static deleteBrokenLine(node:any):void{
        FlowDesignVar.connectNodes=FlowDesignVar.connectNodes.filter((item:any)=>item.obj1.selfId!=node.selfId && item.obj2.selfId!=node.selfId);
        FlowDesignVar.connectIds=FlowDesignVar.connectIds.filter((item:any)=>item.indexOf(node.selfId)==-1);
        FlowDesignVar.canvas.connectTextArray=FlowDesignVar.canvas.connectTextArray.filter((item:any)=>item.selfId.indexOf(node.selfId)==-1);
    }
    //画连线的两个节点中，其中一个节点是点的时候，需要判断以下条件。1-连接两个流程节点的整个折线，只能一个方向，不能出现两边往中间走的情况（即点只能一进一出）；2-不能出现一个点有三个连线的情况
    public static async beforeDrawPointConnect(connectNodes:any):Promise<any>{
        let startNode=connectNodes.obj1;//开始节点
        let node=connectNodes.obj2;//结束节点
        if(startNode.nodeSelfType!=FlowDesignVar.connectPoint && node.nodeSelfType!=FlowDesignVar.connectPoint){//如果是流程步骤节点和流程步骤节点直接相连，则直接允许，因为不需要判断箭头方向
            return connectNodes;
        }

        if(startNode.nodeSelfType!=FlowDesignVar.connectPoint || node.nodeSelfType!=FlowDesignVar.connectPoint){//有一个是流程具体步骤节点
            if(startNode.nodeSelfType==FlowDesignVar.connectPoint){
                if(!ConnectUtil.checkPoint(startNode))return undefined;
                let _connectNodes=ConnectUtil.findEnd(startNode);
                if(_connectNodes && _connectNodes.obj1.nodeSelfType==FlowDesignVar.connectPoint){//这条线路上有具体的流程步骤节点了
                    connectNodes={obj1:node,obj2:startNode};
                }
            }else if(node.nodeSelfType==FlowDesignVar.connectPoint){
                if(!ConnectUtil.checkPoint(node))return undefined;
                let _connectNodes=ConnectUtil.findEnd(node);
                if(_connectNodes && _connectNodes.obj1.nodeSelfType!=FlowDesignVar.connectPoint){//这条线路上有具体的流程步骤节点了
                    connectNodes={obj1:node,obj2:startNode};
                }
            }
        }else{//两个都是点
            if(!ConnectUtil.checkPoint(startNode))return undefined;
            if(!ConnectUtil.checkPoint(node))return undefined;
            let _connectNodes_1=ConnectUtil.findEnd(startNode);
            let _connectNodes_2=ConnectUtil.findEnd(node);
            if(_connectNodes_1){
                _connectNodes_1.path=_connectNodes_1.arrPath.attrs.path;
                let startNode=_connectNodes_1.obj1;
                let endNode=_connectNodes_1.obj2;
                if(_connectNodes_1.obj1.nodeSelfType!=FlowDesignVar.connectPoint){
                    _connectNodes_1.obj1=endNode;
                    _connectNodes_1.obj2=startNode;
                }
                ConnectUtil.createConnectText(_connectNodes_1);
            }else if(_connectNodes_2){
                _connectNodes_2.path=_connectNodes_2.arrPath.attrs.path;
                let startNode=_connectNodes_2.obj1;
                let endNode=_connectNodes_2.obj2;
                if(_connectNodes_2.obj1.nodeSelfType!=FlowDesignVar.connectPoint){
                    _connectNodes_2.obj1=endNode;
                    _connectNodes_2.obj2=startNode;
                }
                ConnectUtil.createConnectText(_connectNodes_2);
            }
        }
        return new Promise((resolve, reject) => {//恶意在这里面做一些等待的操作
            resolve(connectNodes);
        });
        // return connectNodes;
    }
    //node肯定是一个点，不是具体流程步骤节点
    public static findEnd(node:any):any{//返回结果是undefined或者具体的流程节点连接信息
        for(let i=0;i<FlowDesignVar.connectNodes.length;i++){//只能用for循环，如果用find的话，递归调用返回值有误
            let name:string='';
            if(FlowDesignVar.connectNodes[i].obj1.selfId==node.selfId){
                name='obj2';
            }else if(FlowDesignVar.connectNodes[i].obj2.selfId==node.selfId){
                name='obj1';
            }
            if(name){//如果有节点与传入节点相连
                if(FlowDesignVar.connectNodes[i][name].nodeSelfType==FlowDesignVar.connectPoint){//与它相连的节点也是一个点，则递归调用，直到找到线段终点或者相连节点是具体的流程步骤节点为止
                    return ConnectUtil.findEnd(FlowDesignVar.connectNodes[i][name]);
                }else{
                    return FlowDesignVar.connectNodes[i];//具体的流程步骤节点连接信息
                }
            }
        }
    }
    //判断点是否合法（只能一进一出）
    public static checkPoint(node:any):boolean{
        let i=0;
        FlowDesignVar.connectIds.forEach((item:any)=>{
            if(item.indexOf(node.selfId)>-1)i++;
        });
        return i<2;
    }
}